import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import { useEditFeedMutation, useGetFeedDetailsQuery } from '../../../api/api'
import LoadingSpinner from '../../../components/atoms/loadingSpinner/LoadingSpinner'
import CheckboxInput from '../../../components/organisms/checkboxInput/CheckboxInput'
import Schedules from '../../../components/organisms/insertItem/Schedules'
import NumberInput from '../../../components/organisms/numberInput/NumberInput'
import PreviewEdited from '../../../components/organisms/preview/PreviewEdited'
import SelectBox from '../../../components/organisms/selectBox/SelectBox'
import TextInput from '../../../components/organisms/textInput/TextInput'
import { TresponseError } from '../../../types/Tgeneral'
import { isExtraValidJson, validateSchedule } from '../../../util/tools/tools'
import HtmlSourceSelectBox from '../sharedComponents/htmlSourceSelectBox/HtmlSourceSelectBox'
import TemplateSelectBox from '../sharedComponents/templateSelectBox/TemplateSelectBox'
import ZipSourceSelectBox from '../sharedComponents/zipSourceSelectBox/ZipSourceSelectBox'
import { FeedAuth } from './feedAuth/FeedAuth'
import useAllHooks from './hooksAndStates'
import { Airtable } from './publishers/airtable/Airtable'
import { Debug } from './publishers/debug/Debug'
import { Jass } from './publishers/jass/Jass'
import { Other } from './publishers/other/Other'
import { Schalttool } from './publishers/schalttool/Schalttool'
import ReaderConfig from './readerConfig/ReaderConfig'
import ResponseResult from './responseResult/ResponseResult'

const notify = (message: string, autoClose = 3000) => toast(message, { autoClose })

const EditFeed: React.FC = () => {
  const { feedId } = useParams()

  const [
    editFeed,
    {
      isLoading: isUpdating,
      isError: isErrorMutation,
      error: errorFeedMutation,
      isSuccess,
      data: updateResponse,
    },
  ] = useEditFeedMutation()
  const { data: initialValues, isLoading } = useGetFeedDetailsQuery(feedId)

  const {
    name,
    setName,
    url,
    setUrl,
    htmlSource,
    setHtmlSource,
    ignoreUrlSourceHttpErrors,
    setIgnoreUrlSourceHttpErrors,
    ignoreZipSourceHttpErrors,
    setIgnoreZipSourceHttpErrors,
    zipSource,
    setZipSource,
    template,
    setTemplate,
    publisher,
    setPublisher,
    defaultPublisher,
    autoUnpublish,
    setAutoUnpublish,
    isActive,
    setIsActive,
    reader,
    setReader,
    readersList,
    publishersList,
    editedData,
    uniqueId,
    serverResponse,
    setServerResponse,
    schedule,
    setSchedule,
    cronValue,
    setCronValue,
    monthlySoftQuota,
    setMonthlySoftQuota,
    monthlyHardQuota,
    setMonthlyHardQuota,
    quotaThreshold,
    setQuotaThreshold,
    recipientEmail,
    setRecipientEmail,
    authorization,
    setAuthorization,
    authorizationConfigs,
    republishExpired,
    setRepublishExpired,
    disableUpdates,
    setDisableUpdates,
    readJsonLdSchema,
    setReadJsonLdSchema,
  } = useAllHooks(initialValues)

  useEffect(() => {
    if (errorFeedMutation) {
      const errorObject: TresponseError = { ...errorFeedMutation } // prevents typescript errors
      errorObject?.data?.message && notify(errorObject.data.message)
    }
    isSuccess && notify('Updated successfully!')
    isUpdating && notify('Updating...', 700)
    updateResponse && setServerResponse(updateResponse)
    errorFeedMutation && setServerResponse(errorFeedMutation)
  }, [setServerResponse, errorFeedMutation, isSuccess, isUpdating, updateResponse])

  if (isLoading) return <LoadingSpinner />

  const publisherConfig = initialValues.publisherConfig

  if (publisher) {
    return (
      <div className='EditFeed pageContainer'>
        <div className='breadcrumbs'>
          <span>
            <Link to='/feeds'>
              <p>feeds / </p>
            </Link>
          </span>
          <span>
            {name} - {feedId}
          </span>
        </div>
        <h1>{name}</h1>
        <form className='' onSubmit={handleSave} onReset={handelReset}>
          <div className='greyRoundContainer'>
            <div className='inputContainer'>
              <TextInput
                label='Name'
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                uniqueId={uniqueId}
              />
              <TextInput
                label='Url'
                value={url}
                onChange={(e) => setUrl(e.currentTarget.value)}
                uniqueId={uniqueId}
              />
              <TextInput
                label='Recipient Email'
                value={recipientEmail}
                onChange={(e) => setRecipientEmail(e.currentTarget.value)}
                uniqueId={uniqueId}
                required={false}
                type='email'
              />
              <HtmlSourceSelectBox onChange={setHtmlSource} defaultValue={htmlSource} />
              {htmlSource === 'template' && (
                <TemplateSelectBox onChange={(e) => setTemplate(e)} defaultValue={template} />
              )}
              {htmlSource === 'url' && (
                <CheckboxInput
                  label='Ignore HTTP errors'
                  onChange={setIgnoreUrlSourceHttpErrors}
                  uniqueId={uniqueId}
                  checked={ignoreUrlSourceHttpErrors}
                  idAttr='IgnoreUrlSourceHttpErrors'
                />
              )}

              <ZipSourceSelectBox onChange={setZipSource} defaultValue={zipSource} />
              {zipSource === 'url' && (
                <CheckboxInput
                  label='Ignore zip source HTTP errors'
                  onChange={setIgnoreZipSourceHttpErrors}
                  uniqueId={uniqueId}
                  checked={ignoreZipSourceHttpErrors}
                  idAttr='ignoreZipSourceHttpErrors'
                />
              )}
              <CheckboxInput
                label='Read JSON+LD schema'
                onChange={setReadJsonLdSchema}
                uniqueId={uniqueId}
                checked={readJsonLdSchema}
                idAttr='Read JSON+LD schema'
              />
              <FeedAuth
                authorization={authorization}
                setAuthorization={setAuthorization}
                authorizationConfigs={authorizationConfigs}
                initialValues={initialValues}
              />

              <Schedules
                label='Schedule'
                onChange={(e) => setCronValue(e.currentTarget.value)}
                setState={setSchedule}
                uniqueId={uniqueId}
                inputValue={cronValue}
                defaultValue={cronValue}
                schedule={schedule}
                setSchedule={setSchedule}
              />

              <div className='quotas'>
                <NumberInput
                  label='Soft monthly quota'
                  value={monthlySoftQuota}
                  onChange={(e) => setMonthlySoftQuota(e.currentTarget.valueAsNumber)}
                  max={100000}
                  min={0}
                  uniqueId={uniqueId}
                  idAttr='SoftMonthly'
                />
                <NumberInput
                  label='Hard monthly quota'
                  value={monthlyHardQuota}
                  onChange={(e) => setMonthlyHardQuota(e.currentTarget.valueAsNumber)}
                  max={100000}
                  min={0}
                  uniqueId={uniqueId}
                  idAttr='hardMonthly'
                />
                <NumberInput
                  label='Quota threshold'
                  value={quotaThreshold}
                  onChange={(e) => setQuotaThreshold(e.currentTarget.valueAsNumber)}
                  max={100}
                  min={10}
                  uniqueId={uniqueId}
                  idAttr='QuotaThreshold'
                />
              </div>
            </div>
            <div className='checks'>
              <CheckboxInput
                label='Active'
                onChange={setIsActive}
                uniqueId={uniqueId}
                checked={isActive}
                idAttr='KeepActive'
              />
              <CheckboxInput
                label='Automatically unpublish jobs removed from the feed'
                onChange={setAutoUnpublish}
                uniqueId={uniqueId}
                checked={autoUnpublish}
                idAttr='AutoUnpublish'
              />
              <CheckboxInput
                label='Republish expired postings'
                onChange={setRepublishExpired}
                checked={republishExpired}
                idAttr='republish'
                uniqueId={uniqueId}
              />
              <CheckboxInput
                label='Disable updates'
                onChange={setDisableUpdates}
                checked={disableUpdates}
                idAttr='disableUpdates'
                uniqueId={uniqueId}
              />
            </div>
          </div>
          <div className='readerContainer greyRoundContainer'>
            {reader && readersList && (
              <SelectBox
                label='Reader'
                onChange={setReader}
                selectList={readersList}
                uniqueId={uniqueId}
                defaultValue={reader}
                styleType='subFormSelectBox'
              />
            )}
            <ReaderConfig
              initialValues={initialValues?.readerConfig}
              reader={reader}
              htmlSource={htmlSource}
            />
          </div>
          <div className='publisherContainer greyRoundContainer'>
            {publisher && publishersList && (
              <SelectBox
                label='Publisher'
                onChange={setPublisher}
                selectList={publishersList}
                uniqueId={uniqueId}
                defaultValue={publisher}
                styleType='subFormSelectBox'
              />
            )}
            {publisher === 'jass' && (
              <Jass
                defaultPublisher={defaultPublisher}
                publisher={publisher}
                publisherConfig={publisherConfig}
              />
            )}
            {publisher === 'schalttool' && (
              <Schalttool
                defaultPublisher={defaultPublisher}
                publisher={publisher}
                publisherConfig={publisherConfig}
              />
            )}
            {publisher === 'airtable' && (
              <Airtable
                defaultPublisher={defaultPublisher}
                publisher={publisher}
                publisherConfig={publisherConfig}
                htmlSource={htmlSource}
              />
            )}
            {publisher !== 'jass' &&
              publisher !== 'schalttool' &&
              publisher !== 'debug' &&
              publisher !== 'airtable' && (
                <Other
                  defaultPublisher={defaultPublisher}
                  publisher={publisher}
                  publisherConfig={publisherConfig}
                />
              )}
            {publisher === 'debug' && (
              <Debug
                defaultPublisher={defaultPublisher}
                publisher={publisher}
                publisherConfig={publisherConfig}
              />
            )}
          </div>
          <PreviewEdited editedData={editedData} />
          <div className='buttons'>
            <button className='CancelButt buttonBase' type='reset'>
              Revert
            </button>
            <button
              className='SaveButt buttonBase'
              type='submit'
              disabled={isUpdating || isLoading}
            >
              Save
            </button>
          </div>
        </form>
        {!!Object.keys(serverResponse).length && (
          <ResponseResult serverResponse={serverResponse} isError={isErrorMutation} />
        )}
        <ToastContainer limit={3} position='top-center' />
      </div>
    )
  } else {
    return <h3>No publisher. Needs debug!</h3>
  }

  async function handelReset(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    window.location.reload()
  }

  async function handleSave(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (!isExtraValidJson(notify)) {
      return
    }
    validateSchedule(schedule)
      ? await editFeed({ feedId, editedData })
      : notify('Schedule must be in right cron format!')
  }
}

export default EditFeed
