import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useGetPublishersListQuery, useGetReadersListQuery } from '../../../api/api'
import { RootState } from '../../../redux/store'
import { Tauthorization, Tfeed, ThtmlSource, TzipSource } from '../../../types/Tfeeds'
import { setReaderConfig } from './reduxStates'

const useAllStates = (initialValues: Tfeed) => {
  const uniqueId = '651988'

  const {
    jassConfig,
    schalttoolConfig,
    debugConfig,
    otherConfig,
    readerConfig,
    airtableConfig,
    authorizationConfigs: authRedux,
  } = useSelector((state: RootState) => state.editFeed)
  const { data: readersList } = useGetReadersListQuery()
  const { data: publishersList } = useGetPublishersListQuery()

  const dispatch = useDispatch()
  const { feedId } = useParams()

  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [htmlSource, setHtmlSource] = useState<ThtmlSource>('url')
  const [ignoreUrlSourceHttpErrors, setIgnoreUrlSourceHttpErrors] = useState(false)
  const [zipSource, setZipSource] = useState<TzipSource>('create')
  const [ignoreZipSourceHttpErrors, setIgnoreZipSourceHttpErrors] = useState(false)
  const [template, setTemplate] = useState('')
  const [publisher, setPublisher] = useState('')
  const [defaultPublisher, setDefaultPublisher] = useState('')
  const [publisherConfig, setPublisherConfig] = useState({})
  const [autoUnpublish, setAutoUnpublish] = useState(false)
  const [isActive, setIsActive] = useState(true)
  const [reader, setReader] = useState('')
  const [schedule, setSchedule] = useState([''])
  const [serverResponse, setServerResponse] = useState({})
  const [cronValue, setCronValue] = useState('0 * * * *')
  const [monthlySoftQuota, setMonthlySoftQuota] = useState<number>(0)
  const [monthlyHardQuota, setMonthlyHardQuota] = useState<number>(0)
  const [quotaThreshold, setQuotaThreshold] = useState<number>(0)
  const [recipientEmail, setRecipientEmail] = useState('')
  const [authorization, setAuthorization] = useState<Tauthorization>('none')
  const [authorizationConfigs, setAuthorizationConfigs] = useState({})
  const [republishExpired, setRepublishExpired] = useState(false)
  const [disableUpdates, setDisableUpdates] = useState(false)
  const [readJsonLdSchema, setReadJsonLdSchema] = useState(false)

  const [editedData, setEditedData] = useState<Tfeed>({})

  useEffect(() => {
    if (initialValues) {
      const {
        name,
        url,
        publisher,
        reader,
        isActive,
        autoUnpublish,
        htmlSource,
        ignoreUrlSourceHttpErrors,
        zipSource,
        ignoreZipSourceHttpErrors,
        template,
        readerConfig,
        publisherConfig,
        schedule,
        monthlySoftQuota,
        monthlyHardQuota,
        quotaThreshold,
        recipientEmail,
        authorizationConfigs,
        republishExpired,
        disableUpdates,
        readJsonLdSchema,
      } = initialValues
      setName(name)
      setUrl(url)
      setPublisher(publisher)
      setDefaultPublisher(publisher)
      setReader(reader)
      setIsActive(isActive)
      setAutoUnpublish(autoUnpublish)
      setSchedule(schedule)
      setHtmlSource(htmlSource)
      setIgnoreUrlSourceHttpErrors(ignoreUrlSourceHttpErrors)
      setZipSource(zipSource)
      setIgnoreZipSourceHttpErrors(ignoreZipSourceHttpErrors)
      setTemplate(template)
      setPublisherConfig(publisherConfig)
      setMonthlySoftQuota(monthlySoftQuota)
      setMonthlyHardQuota(monthlyHardQuota)
      setQuotaThreshold(quotaThreshold)
      setRecipientEmail(recipientEmail)
      setAuthorizationConfigs(authorizationConfigs)
      setRepublishExpired(republishExpired)
      setDisableUpdates(disableUpdates)
      setReadJsonLdSchema(readJsonLdSchema)
      dispatch(setReaderConfig(readerConfig))
    }
  }, [initialValues, dispatch])

  useEffect(() => {
    let publisherConfig
    switch (publisher) {
      case 'schalttool':
        publisherConfig = schalttoolConfig
        break
      case 'jass':
        publisherConfig = jassConfig
        break
      case 'airtable':
        publisherConfig = airtableConfig
        break
      case 'debug':
        publisherConfig = debugConfig
        break
      default:
        publisherConfig = otherConfig
        break
    }

    const authorizationConfigs = authorization === 'none' ? {} : authRedux
    let templateClone = ''
    if (htmlSource === 'template') {
      if (!template) templateClone = 'basic-html-body'
      else templateClone = template
    }
    const editedObject: Tfeed = {
      name,
      reader,
      readerConfig,
      publisher,
      publisherConfig,
      autoUnpublish,
      isActive,
      url,
      htmlSource,
      ignoreUrlSourceHttpErrors,
      zipSource,
      ignoreZipSourceHttpErrors,
      template: templateClone,
      schedule,
      monthlySoftQuota,
      monthlyHardQuota,
      quotaThreshold,
      recipientEmail,
      authorization,
      authorizationConfigs,
      republishExpired,
      disableUpdates,
      readJsonLdSchema,
    }

    setEditedData(editedObject)
  }, [
    schalttoolConfig,
    readJsonLdSchema,
    jassConfig,
    otherConfig,
    debugConfig,
    name,
    url,
    htmlSource,
    ignoreUrlSourceHttpErrors,
    zipSource,
    ignoreZipSourceHttpErrors,
    template,
    publisher,
    autoUnpublish,
    isActive,
    reader,
    readerConfig,
    schedule,
    monthlySoftQuota,
    monthlyHardQuota,
    quotaThreshold,
    airtableConfig,
    recipientEmail,
    authorization,
    authRedux,
    republishExpired,
    disableUpdates,
  ])

  return {
    dispatch,
    name,
    setName,
    url,
    htmlSource,
    setHtmlSource,
    ignoreUrlSourceHttpErrors,
    setIgnoreUrlSourceHttpErrors,
    zipSource,
    setZipSource,
    ignoreZipSourceHttpErrors,
    setIgnoreZipSourceHttpErrors,
    setUrl,
    template,
    setTemplate,
    publisher,
    setPublisher,
    defaultPublisher,
    setDefaultPublisher,
    publisherConfig,
    setPublisherConfig,
    autoUnpublish,
    setAutoUnpublish,
    isActive,
    setIsActive,
    reader,
    setReader,
    readerConfig,
    readersList,
    publishersList,
    jassConfig,
    schalttoolConfig,
    otherConfig,
    editedData,
    setEditedData,
    feedId,
    uniqueId,
    serverResponse,
    setServerResponse,
    schedule,
    setSchedule,
    cronValue,
    setCronValue,
    monthlySoftQuota,
    setMonthlySoftQuota,
    monthlyHardQuota,
    setMonthlyHardQuota,
    quotaThreshold,
    setQuotaThreshold,
    recipientEmail,
    setRecipientEmail,
    authorization,
    setAuthorization,
    authorizationConfigs,
    setAuthorizationConfigs,
    republishExpired,
    setRepublishExpired,
    disableUpdates,
    setDisableUpdates,
    readJsonLdSchema,
    setReadJsonLdSchema,
  }
}

export default useAllStates
