import { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import { useAddFeedMutation } from '../../../api/api'
import CheckboxInput from '../../../components/organisms/checkboxInput/CheckboxInput'
import Schedules from '../../../components/organisms/insertItem/Schedules'
import NumberInput from '../../../components/organisms/numberInput/NumberInput'
import PreviewEdited from '../../../components/organisms/preview/PreviewEdited'
import SelectBox from '../../../components/organisms/selectBox/SelectBox'
import TextInput from '../../../components/organisms/textInput/TextInput'
import { TresponseError } from '../../../types/Tgeneral'
import { isExtraValidJson, validateSchedule } from '../../../util/tools/tools'
import ResponseResult from '../editFeed/responseResult/ResponseResult'
import HtmlSourceSelectBox from '../sharedComponents/htmlSourceSelectBox/HtmlSourceSelectBox'
import TemplateSelectBox from '../sharedComponents/templateSelectBox/TemplateSelectBox'
import ZipSourceSelectBox from '../sharedComponents/zipSourceSelectBox/ZipSourceSelectBox'
import { FeedAuth } from './feedAuth/FeedAuth'
import { useAllHooks } from './hooksAndStates'
import { Other } from './publishers/Other/Other'
import { Airtable } from './publishers/airtable/Airtable'
import { Debug } from './publishers/debug/Debug'
import { Jass } from './publishers/jass/Jass'
import { Schalttool } from './publishers/schalttool/Schalttool'
import ReaderConfig from './readerConfig/ReaderConfig'

const notify = (message: string, autoClose = 3000) => toast(message, { autoClose })

const AddNewFeed: React.FC = () => {
  const [addFeed, { isError, error, isLoading, isSuccess, data: addFeedResponse }] =
    useAddFeedMutation()

  const {
    name,
    setName,
    url,
    htmlSource,
    setHtmlSource,
    ignoreUrlSourceHttpErrors,
    setIgnoreUrlSourceHttpErrors,
    ignoreZipSourceHttpErrors,
    setIgnoreZipSourceHttpErrors,
    zipSource,
    setZipSource,
    setUrl,
    template,
    setTemplate,
    publisher,
    setPublisher,
    autoUnpublish,
    setAutoUnpublish,
    isActive,
    setIsActive,
    reader,
    setReader,
    readersList,
    publishersList,
    uniqueId,
    serverResponse,
    setServerResponse,
    newFeedData,
    schedule,
    setSchedule,
    cronValue,
    setCronValue,
    monthlySoftQuota,
    setMonthlySoftQuota,
    monthlyHardQuota,
    setMonthlyHardQuota,
    quotaThreshold,
    setQuotaThreshold,
    recipientEmail,
    setRecipientEmail,
    authorization,
    setAuthorization,
    authorizationConfigs,
    setAuthorizationConfigs,
    republishExpired,
    setRepublishExpired,
    disableUpdates,
    setDisableUpdates,
    readJsonLdSchema,
    setReadJsonLdSchema,
  } = useAllHooks()

  useEffect(() => {
    if (error) {
      const errorObject: TresponseError = { ...error } // prevents typescript errors
      errorObject?.data?.message && notify(errorObject.data.message)
    }
    isSuccess && notify('Added successfully!')
    isLoading && notify('Adding...', 700)
    addFeedResponse && setServerResponse(addFeedResponse)
    error && setServerResponse(error)
  }, [setServerResponse, error, isSuccess, isLoading, addFeedResponse])

  return (
    <div className='AddNewFeed pageContainer'>
      <h1>Add New Feed</h1>
      <form className='' onSubmit={handleSave}>
        <div className='greyRoundContainer'>
          <div className='inputContainer'>
            <TextInput
              label='Name'
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              uniqueId={uniqueId}
            />
            <TextInput
              label='Url'
              value={url}
              onChange={(e) => setUrl(e.currentTarget.value)}
              uniqueId={uniqueId}
            />
            <TextInput
              label='Recipient Email'
              value={recipientEmail}
              onChange={(e) => setRecipientEmail(e.currentTarget.value)}
              uniqueId={uniqueId}
              required={false}
              type='email'
            />
            <HtmlSourceSelectBox onChange={setHtmlSource} defaultValue={htmlSource} />
            {htmlSource === 'template' && (
              <TemplateSelectBox onChange={(e) => setTemplate(e)} defaultValue={template} />
            )}
            {htmlSource === 'url' && (
              <CheckboxInput
                label='Ignore HTTP errors'
                onChange={setIgnoreUrlSourceHttpErrors}
                uniqueId={uniqueId}
                checked={ignoreUrlSourceHttpErrors}
                idAttr='IgnoreUrlSourceHttpErrors'
              />
            )}

            <ZipSourceSelectBox onChange={setZipSource} defaultValue={zipSource} />
            {zipSource === 'url' && (
              <CheckboxInput
                label='Ignore zip source HTTP errors'
                onChange={setIgnoreZipSourceHttpErrors}
                uniqueId={uniqueId}
                checked={ignoreZipSourceHttpErrors}
                idAttr='ignoreZipSourceHttpErrors'
              />
            )}

            <CheckboxInput
              label='Read JSON+LD schema'
              onChange={setReadJsonLdSchema}
              uniqueId={uniqueId}
              checked={readJsonLdSchema}
              idAttr='Read JSON+LD schema'
            />
            <FeedAuth
              authorization={authorization}
              setAuthorization={setAuthorization}
              setAuthorizationConfigs={setAuthorizationConfigs}
              authorizationConfigs={authorizationConfigs}
            />

            <Schedules
              label='Add schedule'
              onChange={(e) => setCronValue(e.currentTarget.value)}
              setState={setSchedule}
              uniqueId={uniqueId}
              inputValue={cronValue}
              defaultValue={cronValue}
              schedule={schedule}
              setSchedule={setSchedule}
            />

            <div className='quotas'>
              <NumberInput
                label='Soft monthly quota'
                value={monthlySoftQuota}
                onChange={(e) => setMonthlySoftQuota(e.currentTarget.valueAsNumber)}
                max={100000}
                min={0}
                uniqueId={uniqueId}
                idAttr='SoftMonthly'
              />
              <NumberInput
                label='Hard monthly quota'
                value={monthlyHardQuota}
                onChange={(e) => setMonthlyHardQuota(e.currentTarget.valueAsNumber)}
                max={100000}
                min={0}
                uniqueId={uniqueId}
                idAttr='hardMonthly'
              />
              <NumberInput
                label='Quota threshold'
                value={quotaThreshold}
                onChange={(e) => setQuotaThreshold(e.currentTarget.valueAsNumber)}
                max={100}
                min={10}
                uniqueId={uniqueId}
                idAttr='QuotaThreshold'
              />
            </div>
          </div>

          <div className='checks'>
            <CheckboxInput
              label='Active'
              onChange={setIsActive}
              uniqueId={uniqueId}
              checked={isActive}
              idAttr='IsActive'
            />
            <CheckboxInput
              label='Automatically unpublish jobs removed from the feed'
              onChange={setAutoUnpublish}
              uniqueId={uniqueId}
              checked={autoUnpublish}
              idAttr='AutoUnpublish'
            />
            <CheckboxInput
              label='Republish expired postings'
              onChange={setRepublishExpired}
              checked={republishExpired}
              idAttr='republish'
              uniqueId={uniqueId}
            />
            <CheckboxInput
              label='Disable updates'
              onChange={setDisableUpdates}
              checked={disableUpdates}
              idAttr='disableUpdates'
              uniqueId={uniqueId}
            />
          </div>
        </div>
        <div className='readerContainer greyRoundContainer'>
          {readersList && (
            <SelectBox
              label='Reader'
              onChange={setReader}
              selectList={readersList}
              uniqueId={uniqueId}
              defaultValue={reader}
              required={true}
              styleType='subFormSelectBox'
            />
          )}
          <ReaderConfig reader={reader} htmlSource={htmlSource} />
        </div>
        <div className='publisherContainer greyRoundContainer'>
          {publishersList && (
            <SelectBox
              label='Publisher'
              onChange={setPublisher}
              selectList={publishersList}
              uniqueId={uniqueId}
              defaultValue={publisher}
              styleType='subFormSelectBox'
            />
          )}
          {publisher === 'jass' && <Jass publisher={publisher} />}

          {publisher === 'schalttool' && <Schalttool publisher={publisher} />}
          {publisher === 'airtable' && <Airtable publisher={publisher} />}
          {publisher !== 'jass' &&
            publisher !== 'schalttool' &&
            publisher !== 'debug' &&
            publisher !== 'airtable' && <Other publisher={publisher} />}
          {publisher === 'debug' && <Debug publisher={publisher} />}
        </div>
        <PreviewEdited editedData={newFeedData} />
        <div className='buttons'>
          <button className='SaveButt buttonBase' type='submit'>
            Save
          </button>
        </div>
      </form>
      {!!Object.keys(serverResponse).length && (
        <ResponseResult serverResponse={serverResponse} isError={isError} />
      )}
      {/* toConsider */}

      <ToastContainer limit={3} position='top-center' />
    </div>
  )

  async function handleSave(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (!isExtraValidJson(notify)) {
      return
    }
    validateSchedule(schedule)
      ? await addFeed({ newFeedData })
      : notify('Schedule must be in right cron format!')
  }
}

export default AddNewFeed
